<script>

import { BCard, BCardText, BCardTitle, BEmbed, BRow, BCol, BCardSubTitle, BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, } from "bootstrap-vue";
import Ripple from 'vue-ripple-directive';
import convertToHTML from "markdown-to-html-converter";

export default {
    components: {
        BCard,
        BCardText,
        BButton,
        BModal,
        VBModal,
        BForm,
        BFormInput,
        BFormGroup,
        BCardSubTitle,
        BEmbed,
        BRow,
        BCol,
        BCardTitle,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            own: undefined,
            scriptName: this.$route.params.scriptName,
            invoice: "",
            user: this.$getUser(),
            product: {},
            sellix: {},
            description: "",
        }
    },
    methods: {
        ownScript() {
            var ctx = this;
            ctx.$axios.get(`products/${ctx.scriptName}`).then(function (res) {
                ctx.own = true;
            }).catch(function (err) {
                ctx.own = false;
                if (err.response.data) {
                    ctx.product = err.response.data.product;
                    ctx.sellix = err.response.data.sellix;
                }
                ctx.description = convertToHTML(ctx.product.description);
            });
        },
        claimProduct() {
            var ctx = this;
            console.log(ctx.invoice);
        }
    },
    mounted() {
        var ctx = this;
        ctx.ownScript();
    }
}
</script>

<template>
    <div v-if="own == true">
        own true
    </div>
    <div v-else-if="own == false">
        <b-card :title="product.name">
            <div>
                <b-embed style="border-radius: 10px;" type="iframe" aspect="16by9"
                    src="https://www.youtube.com/embed/G_A490hTUcc" allowfullscreen />
            </div>

            <b-card-text v-html="description"></b-card-text>
            <div class="mt-1 md:mt-1">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                    data-sellix-product="62beef32b0a0a" :data-sellix-custom-discord="user.uid">
                    Buy the script
                </b-button>
                <b-button class="ml-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    v-b-modal.modal-claim>
                    Claim the product
                </b-button>
            </div>
        </b-card>

        <b-modal id="modal-claim" cancel-variant="outline-secondary" hide-footer centered title="Login Form">
            <b-form @submit.prevent="claimProduct">
                <b-form-group>
                    <label for="invoice">Invoice ID:</label>
                    <b-form-input id="invoice" v-model="invoice" required type="text" placeholder="Invoied ID" />

                    <b-button class="mt-1 mb-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success">
                        Claim the product
                    </b-button>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
    <div v-else>
        Loading ....
    </div>
</template>